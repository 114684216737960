.flexBox {
    display: flex;
    align-items: center;
}

.flexBoxStart {
    display: flex;
    align-items: flex-start;
}

.flexBoxBaseline {
    display: flex;
    align-items: baseline;
}

.flexWrap{
    flex-wrap: wrap;
}

.autoFlex {
    flex: 1;
}

.clearFlex {
    flex: 0 0 auto;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.margeCollapse{
    &:before{
        content: "";
        display: table;
    }

    &:after{
        content: "";
        display: table;
    }
}